import React from 'react';
import FunBox01 from '../../Components/FunFactElements/FunBox01';


const FunFact01 = () => {
    const FunData = [
        {
            id: '1',
            img: 'images/funfact/p1.png',
            number: '20',
            name: 'Лет опыта'
        },
        {
            id: '2',
            img: 'images/funfact/p2.png',
            more: 'более',
            number: '1500',
            name: 'Проектов выполнено'
        },
        {
            id: '3',
            img: 'images/funfact/p3.png',
            number: '12',
            name: 'Человек'
        },
        // {
        //     id: '4',
        //     img: 'images/funfact/p4.png',
        //     number: '36',
        //     name: 'Ongoing Project'
        // },
    ];

    return (
        <div id="funfacts" className="funfacts my-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {/* <div className="section_header">
                            <h6 className="section_sub_title">О нас</h6>
                            <h1 className="section_title">Sample text</h1>
                        </div> */}
                        <div className="fun_bottom">
                            <div className="row">
                                {
                                    FunData.map(data =>
                                        <FunBox01
                                            key={data.id}
                                            data={data}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-lg-4">
                        <div className="man_img">
                            <img src="." alt="icon" />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default FunFact01;