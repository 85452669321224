import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer_above px-4 pb-1 pt-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="footer_widget">
                                <h4 className="widget_title">
                                    {/* О нас: */}
                                </h4>
                                <p>Эковата-монтаж: утепление эковатой в СПб и ЛО.</p>
                                <p>Европейские стандарты качества и скандинавские материалы</p>
                                {/* <div className="subscribe">
                                    <form className="form-inline">
                                        <div className="input-group mb-2 mr-sm-2">
                                            <input type="email" className="form-control" placeholder="Email Address" />
                                        </div>
                                        <button type="submit" className="btn">Subscribe</button>
                                    </form>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="footer_widget footer_links">
                                {/* <h4 className="widget_title">
                                    Our Services
                                </h4> */}
                                <div className="footer_nav">
                                    {/* <ul className="footer_menu">
                                        <li className="menu-item"><a href="/project">Web Design</a></li>
                                        <li className="menu-item"><a href="/project-2">Construction</a></li>
                                        <li className="menu-item"><a href="/project-details">Economics</a></li>
                                        <li className="menu-item"><a href="/project-2">Photography</a></li>
                                        <li className="menu-item"><a href="/project">Digital Marketing</a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-12">
                            <div className="footer_widget footer_links">
                                {/* <h4 className="widget_title">
                                    Useful Links
                                </h4> */}
                                <div className="footer_nav">
                                    {/* <ul className="footer_menu">
                                        <li className="menu-item"><a href="/about">About Us</a></li>
                                        <li className="menu-item"><a href="/blog-details">Courses</a></li>
                                        <li className="menu-item"><a href="/project-details">Enrollment</a></li>
                                        <li className="menu-item"><a href="/service-details">Be an Instructor</a></li>
                                        <li className="menu-item"><a href="/contact">Contact Us</a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 p-1">
                            <div className="footer_widget">
                                <h4 className="widget_title mb-3">
                                    Адрес
                                </h4>
                                <ul>
                                    <li><i className="fa fa-map-marker" aria-hidden="true"></i><span>Санкт-Петербург, Финляндская улица, 37В</span></li>
                                    <li><i className="fa fa-envelope" aria-hidden="true"></i><span>contact@ecovata.ru</span></li>
                                    <li><i className="fa fa-clock-o" aria-hidden="true"></i><span>Пн - Вс (9.00 - 21.00)</span></li>
                                </ul>
                                <div className="side_footer_social">
                                    <ul className="bottom_social">
                                        {/* <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                        <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                        <li className="dribbble"><a href="#"><i className="ion-social-dribbble-outline"></i></a></li>
                                        <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                        <li className="linkedin"><a href="#"><i className="ion-social-linkedin-outline"></i></a></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom">
                <div className="container">
                    <div className="footer_bottom_inner">
                        <div className="logo">
                            <a href="index"><img className=" xs:hidden xs:object-scale-down xs:h-14" src="images/ico/eco.png" alt="Logo" /></a>
                        </div>
                        <div className="copyright">
                            <p className="pt-16">&copy;2024 <a href="https://эковата.рф">эковата.рф</a></p>
                        </div>
                        <div className="footer_nav_bottom">
                            {/* <ul>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul> */}
                        </div>
                        <div className="totop">
                            <a href="#"><i className="ion-ios-arrow-up"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;