import React, { useState } from "react";

const Tabs = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const tabs = [
    {
      id: 1,
      tabTitle: "Цена за м3",
      title: "Утепление стен",
      price1: "5400-5900р.",
      price2: "4400-4900р.",
      price3: "3000-3300р.",
      price4: "2900-3200р.",
      content1: "Стены, вертикальные поверхности",
      content2: "Крыши, закрытые полости",
      content3: "Пол, межэтажные перекрытия",
      content4: "Холодный чердак, мансарда",
      title1: "Влажно-клеевой способ",
      title2: "Сухой способ, задувка в закрытую полость",
      title3: "Сухой способ, открытое нанесение",
      title4: "Сухой способ, открытое нанесение",
    },
    {
      id: 2,
      tabTitle: "Цена за м2 (толщина 10см)",
      title: "Title 2",
      price1: "540-590р.",
      price2: "440-490р.",
      price3: "300-330р.",
      price4: "290-320р.",
      content1: "Стены, вертикальные поверхности",
      content2: "Крыши, закрытые полости",
      content3: "Пол, межэтажные перекрытия",
      content4: "Холодный чердак, мансарда",
      title1: "Влажно-клеевой способ",
      title2: "Сухой способ, задувка в закрытую полость",
      title3: "Сухой способ, открытое нанесение",
      title4: "Сухой способ, открытое нанесение",
    },
    {
      id: 3,
      tabTitle: "Цена за м2 (толщина 15см)",
      title: "Title 3",
      price1: "810-885р.",
      price2: "660-735р.",
      price3: "450-495р.",
      price4: "435-480р.",
      content1: "Стены, вертикальные поверхности",
      content2: "Крыши, закрытые полости",
      content3: "Пол, межэтажные перекрытия",
      content4: "Холодный чердак, мансарда",
      title1: "Влажно-клеевой способ",
      title2: "Сухой способ, задувка в закрытую полость",
      title3: "Сухой способ, открытое нанесение",
      title4: "Сухой способ, открытое нанесение",
    },
    // {
    //   id: 4,
    //   tabTitle: 'Tab 4',
    //   title: 'Title 4',
    //   content: 'Contenido de tab 4.'
    // }
  ];

  const handleTabClick = (e) => {
    setCurrentTab(e.target.id);
  };

  return (
    <div className="tabcontainer py-10 p-28 xs:p-0">
      <div className="tabs flex space-x-4 xs:space-x-0">
        {tabs.map((tab, i) => (
          <button
            className="border-bottom border-2 px-0"
            key={i}
            id={tab.id}
            disabled={currentTab === `${tab.id}`}
            onClick={handleTabClick}
          >
            {tab.tabTitle}
          </button>
        ))}
      </div>
      {/* <p className='title'>{tab.title}</p><p>{tab.content}</p><p>{tab.price}</p> */}
      <div className="tabcontent xs:px-0 w-full h-full">
        {tabs.map((tab, i) => (
          <div key={i} className="">
            {currentTab === `${tab.id}` && (
              <div className="grid lg:grid-cols-4 xs:grid-cols-2 sm:gap-2 gap-2 gap-x-6">
                <div class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                  <div class="rounded border-b-2 bg-[#96c73a] border-neutral-100 px-6 py-3 text-black text-2xl font-bold">
                    Стены
                  </div>
                  <div class="p-6">
                    <h5 class="mb-2 text-2xl font-bold leading-tight text-black">
                      {tab.price1}
                    </h5>
                    <p class="my-4 text-base h-12 text-black">
                      {tab.content1}
                    </p>
                  </div>
                  <div class="border-t-2 px-6 py-3 border-neutral-100">
                    {tab.title1}
                  </div>
                </div>
                <div class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                  <div class="rounded border-b-2 bg-[#96c73a] border-neutral-100 px-6 py-3 text-black text-2xl font-bold">
                    Кровля
                  </div>
                  <div class="p-6">
                    <h5 class="mb-2 text-2xl font-bold leading-tight text-black">
                      {tab.price2}
                    </h5>
                    <p class="my-4 text-base h-12 text-black">
                      {tab.content2}
                    </p>
                  </div>
                  <div class="border-t-2 px-8 py-2 border-neutral-100">
                    {tab.title2}
                  </div>
                </div>
                <div class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                  <div class="rounded border-b-2 bg-[#96c73a] border-neutral-100 px-6 py-3 text-black text-2xl font-bold">
                    Пол
                  </div>
                  <div class="p-6">
                    <h5 class="mb-2 text-2xl font-bold leading-tight text-black">
                      {tab.price3}
                    </h5>
                    <p class="my-4 text-base h-12 text-black">
                      {tab.content3}
                    </p>
                  </div>
                  <div class="border-t-2 px-6 py-3 border-neutral-100">
                    {tab.title3}
                  </div>
                </div>
                <div class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                  <div class="rounded border-b-2 bg-[#96c73a] border-neutral-100 px-6 py-3 text-black text-2xl font-bold">
                    Чердак
                  </div>
                  <div class="p-6">
                    <h5 class="mb-2 text-2xl font-bold leading-tight text-black">
                      {tab.price4}
                    </h5>
                    <p class="my-4 text-base h-12 text-black">
                      {tab.content4}
                    </p>
                  </div>
                  <div class="border-t-2 px-6 py-3 border-neutral-100">
                    {tab.title4}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
        {/* //cards */}
        {/* <div class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <div class="border-b-2 bg-lime-500 border-neutral-100 px-6 py-3 dark:border-neutral-600 text-black text-2xl font-bold">
          Стены
          </div>
          <div class="p-6">
            <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
              Special title treatment
            </h5>
            <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
          </div>
          <div class="border-t-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
            2 days ago
          </div>
        </div>
        <div class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <div class="border-b-2 bg-lime-500 border-neutral-100 px-6 py-3 dark:border-neutral-600 text-black text-2xl font-bold">
            Кровля
          </div>
          <div class="p-6">
            <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
              Special title treatment
            </h5>
            <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
          </div>
          <div class="border-t-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
            2 days ago
          </div>
        </div>
        <div class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <div class="border-b-2 bg-lime-500 border-neutral-100 px-6 py-3 dark:border-neutral-600 text-black text-2xl font-bold">
            Пол
          </div>
          <div class="p-6">
            <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
              Special title treatment
            </h5>
            <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
          </div>
          <div class="border-t-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
            2 days ago
          </div>
        </div>
        <div class="block rounded-lg bg-white text-center shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <div class="border-b-2 bg-lime-500 border-neutral-100 px-6 py-3 dark:border-neutral-600 text-black text-2xl font-bold">
            Чердак
          </div>
          <div class="p-6">
            <h5 class="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
              Special title treatment
            </h5>
            <p class="mb-4 text-base text-neutral-600 dark:text-neutral-200">
              With supporting text below as a natural lead-in to additional
              content.
            </p>
          </div>
          <div class="border-t-2 border-neutral-100 px-6 py-3 dark:border-neutral-600 dark:text-neutral-50">
            2 days ago
          </div>
        </div> */}
        {/* //cards */}
      </div>
    </div>
  );
};

export default Tabs;
