import React from "react";
import PageHeader from "../Components/common/PageHeader";
import CommunicationCard from "../Components/ContactElements/CommunicationCard";
import Map2 from "../Components/ContactElements/Map2";
import Banner from "../Containers/Banner";
import { motion } from "framer-motion";
import "./draw.css";

const ContactPage = () => {
  // const ContactData = [
  //     {
  //         id: '1',
  //         iconClass: 'ion-ios-telephone-outline',
  //         infoBody01: 'Phone No',
  //         infoBody02: '+33 987 654 321',
  //         infoBody03: '+33 123 456 789',
  //     },
  //     {
  //         id: '2',
  //         iconClass: 'fa fa-envelope-o',
  //         infoBody01: 'Email Address',
  //         infoBody02: 'builderrine@gmail.com',
  //         infoBody03: 'care@builderrine.com',
  //     },
  //     {
  //         id: '3',
  //         iconClass: 'fa fa-map-marker',
  //         infoBody01: 'Office Address',
  //         infoBody02: 'Gr. Benjamin Street 609<br /> Florida, USA',
  //         infoBodyClass: 'd-none',
  //     },
  // ];

  const transition = { duration: 2, yoyo: Infinity, ease: "linear" }
  const transition2 = { duration: 500, yoyo: Infinity, ease: "linear" }

  return (
    <>
      <PageHeader
        activeClass01="active"
        activeClass02="d-none"
        routeName01="Contact Us"
        heading="Контакты"
      />

      <div className="main_wrapper">
        <div className="section pt-[50px]">
          <div className="container flex">
            <Map2 />
          </div>
        </div>
          {/* <svg xmlns="http://www.w3.org/2000/svg" width="450" height="430">
        <motion.path
          d="m 1.57813,52.3633 c 0.05859,0 0.10156,0.0469 0.10156,0.1015 0,0.0586 -0.04297,0.1055 -0.10156,0.1055 -0.05079,0 -0.09766,-0.0469 -0.09766,-0.1055 0,-0.0546 0.04687,-0.1015 0.09766,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 5.55469,52.3633 c 0.05469,0 0.10156,0.0469 0.10156,0.1015 0,0.0586 -0.04687,0.1055 -0.10156,0.1055 -0.05469,0 -0.10156,-0.0469 -0.10156,-0.1055 0,-0.0546 0.04687,-0.1015 0.10156,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 1.57813,48.6289 c 0.05859,0 0.10156,0.0469 0.10156,0.1055 0,0.0586 -0.04297,0.1054 -0.10156,0.1054 -0.05079,0 -0.09766,-0.0468 -0.09766,-0.1054 0,-0.0586 0.04687,-0.1055 0.09766,-0.1055 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 5.55469,48.6289 c 0.05469,0 0.10156,0.0469 0.10156,0.1055 0,0.0586 -0.04687,0.1054 -0.10156,0.1054 -0.05469,0 -0.10156,-0.0468 -0.10156,-0.1054 0,-0.0586 0.04687,-0.1055 0.10156,-0.1055 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 3.47266,43.8594 c 0,-6.9571 0,-13.9219 0,-20.8828 0,-0.1875 0,-0.375 0,-0.5586 0.0039,-0.0742 0,-0.2539 0.23047,-0.4102 L 4.10156,21.7773 26.1445,9.0625 c 0.1719,-0.10156 0.3477,-0.19922 0.5196,-0.29687 0.1328,-0.05079 0.2812,-0.10938 0.457,-0.01954 L 27.6602,9.05859 50.1016,22 c 0.125,0.0977 0.2343,0.1797 0.2343,0.4023 l -0.0039,29.504 c -0.0234,0.4257 -0.3281,0.4414 -0.4531,0.4414 H 12.7344 c -0.3047,0 -0.4102,-0.2891 -0.4063,-0.4414 L 12.3242,26.7422 c 0,-0.1211 0.043,-0.3125 0.2539,-0.4102 l 14.0078,-8.0703 c 0.2266,-0.1133 0.3282,-0.1836 0.5938,-0.0273 l 14.3281,8.1211 c 0.1914,0.082 0.2422,0.2968 0.2422,0.3867 v 16.6719 c 0,0.3828 -0.3125,0.4336 -0.4531,0.4492 H 27.3203 c -0.3398,0 -0.4023,-0.3242 -0.418,-0.4258 v -4.8828"
          fill="transparent"
          strokeWidth="3.9"
          stroke="#96c73a"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 26.832,33.793 c 0.0508,0 0.0977,0.0508 0.0977,0.1054 0,0.0586 -0.0469,0.1055 -0.0977,0.1055 -0.0586,0 -0.1054,-0.0469 -0.1054,-0.1055 0,-0.0546 0.0468,-0.1054 0.1054,-0.1054 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 24.4805,31.0664 c 0.0547,0 0.0976,0.0469 0.0976,0.1055 0,0.0586 -0.0429,0.1054 -0.0976,0.1054 -0.0586,0 -0.0977,-0.0468 -0.0977,-0.1054 0,-0.0586 0.0391,-0.1055 0.0977,-0.1055 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 29.1016,31.0664 c 0.0547,0 0.0976,0.0469 0.0976,0.1055 0,0.0586 -0.0429,0.1054 -0.0976,0.1054 C 29.043,31.2773 29,31.2305 29,31.1719 c 0,-0.0586 0.043,-0.1055 0.1016,-0.1055 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 22.332,28.4102 c 0.0547,0 0.1016,0.0429 0.1016,0.1015 0,0.0586 -0.0469,0.1055 -0.1016,0.1055 -0.0547,0 -0.0976,-0.0469 -0.0976,-0.1055 0,-0.0586 0.0429,-0.1015 0.0976,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 26.832,28.4102 c 0.0508,0 0.0977,0.0429 0.0977,0.1015 0,0.0586 -0.0469,0.1055 -0.0977,0.1055 -0.0586,0 -0.1054,-0.0469 -0.1054,-0.1055 0,-0.0586 0.0468,-0.1015 0.1054,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 31.3242,28.4102 c 0.0586,0 0.1055,0.0429 0.1055,0.1015 0,0.0586 -0.0469,0.1055 -0.1055,0.1055 -0.0508,0 -0.0976,-0.0469 -0.0976,-0.1055 0,-0.0586 0.0468,-0.1015 0.0976,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1, delay: 5 }}
          transition={transition2}
        />
        <motion.path
          d="m 57.7148,34.2148 0.8829,-0.6914 c 0.3007,0.418 0.6914,0.7266 1.1679,0.9141 0.4727,0.1914 0.961,0.2852 1.4571,0.2852 0.4961,0 0.8945,-0.0782 1.2812,-0.2383 0.3789,-0.1563 0.7109,-0.3789 0.9922,-0.6719 0.2812,-0.2852 0.5078,-0.6211 0.6758,-1.0117 0.1679,-0.3945 0.2734,-0.8164 0.3125,-1.2695 h -5.0703 v -1.0235 h 5.0468 c -0.0586,-0.4375 -0.1796,-0.8437 -0.3515,-1.2109 -0.1797,-0.3711 -0.4102,-0.6875 -0.6953,-0.9492 -0.2852,-0.2579 -0.6211,-0.4649 -1.0039,-0.6094 -0.3829,-0.1445 -0.8047,-0.2227 -1.2696,-0.2227 -0.3359,0 -0.6406,0.0391 -0.914,0.1172 -0.2774,0.082 -0.5157,0.1797 -0.7266,0.3008 -0.2109,0.1172 -0.3867,0.2539 -0.5352,0.4023 -0.1484,0.1524 -0.2578,0.293 -0.3359,0.4297 l -0.793,-0.7812 c 0.1524,-0.2188 0.3438,-0.4258 0.5743,-0.6094 0.2304,-0.1914 0.4961,-0.3555 0.7851,-0.4961 0.2891,-0.1367 0.6055,-0.2461 0.9531,-0.3281 0.3399,-0.0781 0.7071,-0.1172 1.0938,-0.1172 0.7148,0 1.3516,0.1172 1.9219,0.3555 0.5625,0.2304 1.039,0.5586 1.4336,0.9765 0.3906,0.418 0.6875,0.9141 0.8945,1.4844 0.207,0.5703 0.3086,1.1953 0.3086,1.8789 0,0.6797 -0.1094,1.3086 -0.3281,1.8789 -0.2188,0.5742 -0.5313,1.0664 -0.9336,1.4844 -0.4024,0.418 -0.8868,0.7422 -1.4493,0.9805 -0.5664,0.2382 -1.1914,0.3554 -1.8789,0.3554 -0.4062,0 -0.7851,-0.0468 -1.1367,-0.1289 -0.3515,-0.0859 -0.6797,-0.1992 -0.9804,-0.3437 -0.2969,-0.1407 -0.5665,-0.3086 -0.8008,-0.5 -0.2344,-0.1953 -0.4297,-0.4063 -0.5782,-0.6328 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 67.582,35.5898 v -8.9296 h 1.211 v 3.6601 c 0.1992,0 0.3945,0.0078 0.5859,0.0195 0.1914,0.0118 0.3789,0.0274 0.5625,0.043 l 3.2305,-3.7226 h 1.3359 l -3.3203,4.0117 c 0.9844,0.3398 1.7539,0.9101 2.3086,1.7187 0.5586,0.8086 0.9453,1.8789 1.1641,3.2032 h -1.3477 c -0.1289,-0.7383 -0.3086,-1.379 -0.5391,-1.918 -0.2265,-0.5391 -0.5273,-0.9766 -0.8867,-1.3164 -0.3633,-0.336 -0.7969,-0.5899 -1.3047,-0.7461 -0.5078,-0.1563 -1.1054,-0.2266 -1.7851,-0.207 v 4.1875 h -1.211 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 85.3242,31.1133 c 0,0.6914 -0.1211,1.3242 -0.3515,1.8984 -0.2344,0.5742 -0.5625,1.0703 -0.9844,1.4883 -0.4219,0.418 -0.9219,0.7422 -1.4961,0.9727 -0.5742,0.2304 -1.2031,0.3476 -1.8711,0.3476 -0.6719,0 -1.2969,-0.1133 -1.875,-0.3476 -0.5742,-0.2305 -1.0742,-0.5547 -1.4922,-0.9727 -0.4219,-0.418 -0.75,-0.9141 -0.9844,-1.4883 -0.2343,-0.5742 -0.3554,-1.207 -0.3554,-1.8984 0,-0.6875 0.1211,-1.3203 0.3554,-1.8906 0.2344,-0.5743 0.5625,-1.0625 0.9844,-1.4766 0.418,-0.4102 0.918,-0.7344 1.4922,-0.9648 0.5781,-0.2305 1.2031,-0.3438 1.875,-0.3438 0.668,0 1.2969,0.1133 1.8711,0.3438 0.5742,0.2304 1.0742,0.5546 1.4961,0.9648 0.4219,0.4141 0.75,0.9023 0.9844,1.4766 0.2304,0.5703 0.3515,1.2031 0.3515,1.8906 z m -1.3008,0 c 0,-0.4883 -0.082,-0.9453 -0.2382,-1.3789 -0.1602,-0.4336 -0.3907,-0.8164 -0.6797,-1.1446 -0.2969,-0.3281 -0.6524,-0.5859 -1.0742,-0.7812 -0.418,-0.1953 -0.8907,-0.2891 -1.4102,-0.2891 -0.5195,0 -0.9922,0.0938 -1.4102,0.2891 -0.4218,0.1953 -0.7812,0.457 -1.0742,0.7812 -0.2929,0.3282 -0.5234,0.711 -0.6836,1.1446 -0.1562,0.4336 -0.2343,0.8906 -0.2343,1.3789 0,0.4883 0.0781,0.9648 0.2343,1.3984 0.1602,0.4375 0.3907,0.8242 0.6836,1.1524 0.293,0.3281 0.6524,0.5859 1.0742,0.7773 0.418,0.1914 0.8907,0.2852 1.4102,0.2852 0.5195,0 0.9922,-0.0938 1.4102,-0.2852 0.4218,-0.1914 0.7773,-0.4492 1.0742,-0.7773 0.289,-0.3282 0.5195,-0.711 0.6797,-1.1524 0.1562,-0.4336 0.2382,-0.9023 0.2382,-1.3984 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d=""
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="M 87.1055,26.6602 H 90 c 0.3359,0 0.6758,0.0351 1.0234,0.1054 0.3516,0.0742 0.6641,0.1953 0.9414,0.3633 0.2774,0.1758 0.504,0.4063 0.6797,0.7031 0.1797,0.2969 0.2696,0.668 0.2696,1.1133 0,0.4766 -0.1446,0.8906 -0.4258,1.2344 -0.2813,0.3476 -0.6602,0.5742 -1.1289,0.6953 v 0.0273 c 0.2812,0.0313 0.5469,0.1016 0.789,0.2188 0.2383,0.1172 0.4454,0.2617 0.625,0.4492 0.1797,0.1875 0.3164,0.4063 0.418,0.6641 0.0977,0.2578 0.1484,0.5351 0.1484,0.8515 0,0.4024 -0.082,0.7579 -0.246,1.0704 -0.1602,0.3085 -0.3946,0.5742 -0.6915,0.789 -0.2968,0.211 -0.6562,0.3789 -1.0703,0.4844 -0.4101,0.1094 -0.8632,0.1601 -1.3476,0.1601 h -2.875 v -8.9296 z m 1.207,3.8242 h 1.6602 c 0.2109,0 0.4218,-0.0274 0.625,-0.0781 0.2109,-0.0508 0.3945,-0.129 0.5546,-0.2383 0.1602,-0.1055 0.2891,-0.25 0.3868,-0.4336 0.1015,-0.1836 0.1523,-0.4024 0.1523,-0.6641 0,-0.2617 -0.0508,-0.4648 -0.1406,-0.6406 -0.0977,-0.168 -0.2383,-0.3164 -0.4102,-0.4258 -0.1758,-0.1094 -0.3828,-0.1953 -0.6133,-0.2461 -0.2382,-0.0508 -0.496,-0.0742 -0.7656,-0.0742 h -1.4492 z m 0,4.0859 h 1.5742 c 0.2852,0 0.5625,-0.0234 0.8281,-0.082 0.2696,-0.0547 0.504,-0.1485 0.711,-0.2695 0.207,-0.125 0.3672,-0.2891 0.4922,-0.4883 0.1211,-0.1953 0.1797,-0.4414 0.1797,-0.7383 0,-0.5313 -0.1875,-0.918 -0.5586,-1.1719 C 91.168,31.5664 90.6563,31.4414 90,31.4414 h -1.6875 z"
          fill="#96c73a"
          strokeWidth="0"
          stroke="#96c73a"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 95.4844,35.5898 h -1.3399 l 3.836,-8.9296 h 1.1875 l 3.809,8.9296 h -1.375 l -0.911,-2.1953 h -4.3121 z m 1.3008,-3.2539 h 3.5038 l -1.7421,-4.3164 z"
          fill="#96c73a"
          strokeWidth="0"
          stroke="#96c73a"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 107.23,35.5898 h -1.207 v -7.8593 h -2.878 v -1.0703 h 6.964 v 1.0703 h -2.875 v 7.8593 z"
          fill="#96c73a"
          strokeWidth="0"
          stroke="#96c73a"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 111.637,35.5898 h -1.34 l 3.836,-8.9296 h 1.187 l 3.809,8.9296 h -1.375 l -0.91,-2.1953 h -4.313 z m 1.301,-3.2539 h 3.503 l -1.742,-4.3164 z"
          fill="#96c73a"
          strokeWidth="0"
          stroke="#96c73a"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 121.652,34.8359 c 0,0.2188 -0.078,0.4102 -0.238,0.5782 -0.156,0.1679 -0.359,0.2539 -0.605,0.2539 -0.246,0 -0.438,-0.086 -0.598,-0.2461 -0.164,-0.1641 -0.246,-0.3555 -0.246,-0.5742 0,-0.2188 0.078,-0.4258 0.238,-0.5899 0.156,-0.164 0.36,-0.2461 0.606,-0.2461 0.246,0 0.445,0.0821 0.605,0.2383 0.16,0.1602 0.238,0.3555 0.238,0.5781 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 123.57,26.6602 h 2.719 c 0.461,0 0.887,0.0468 1.27,0.1484 0.39,0.0937 0.718,0.2422 0.996,0.4453 0.277,0.2031 0.492,0.4609 0.644,0.7695 0.149,0.3125 0.223,0.6836 0.223,1.1094 0,0.4297 -0.078,0.8047 -0.238,1.1211 -0.161,0.3125 -0.383,0.5742 -0.664,0.7813 -0.282,0.207 -0.622,0.3593 -1.012,0.457 -0.395,0.1016 -0.817,0.1523 -1.274,0.1523 h -1.457 v 3.9493 h -1.207 v -8.9297 z m 1.211,3.9843 h 1.383 c 0.633,0 1.129,-0.1211 1.488,-0.3672 0.356,-0.246 0.539,-0.625 0.539,-1.125 0,-0.5039 -0.183,-0.9023 -0.546,-1.1289 -0.364,-0.2304 -0.852,-0.3398 -1.465,-0.3398 h -1.391 v 2.9648 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 136.051,26.9727 c 0.613,0.0507 1.179,0.1875 1.695,0.4023 0.524,0.2188 0.973,0.5078 1.348,0.875 0.379,0.3594 0.668,0.7852 0.879,1.2695 0.215,0.4805 0.316,1.0196 0.316,1.6094 0,0.5899 -0.101,1.125 -0.316,1.6133 -0.211,0.4883 -0.5,0.9141 -0.879,1.2734 -0.375,0.3633 -0.824,0.6524 -1.348,0.8711 -0.516,0.2188 -1.082,0.3516 -1.695,0.4063 v 1.0078 h -1.207 V 35.293 c -0.625,-0.0547 -1.199,-0.1875 -1.727,-0.4063 -0.519,-0.2187 -0.976,-0.5078 -1.351,-0.8711 -0.379,-0.3593 -0.676,-0.7851 -0.891,-1.2734 -0.215,-0.4883 -0.32,-1.0274 -0.32,-1.6133 0,-0.5859 0.105,-1.1289 0.32,-1.6133 0.215,-0.4883 0.512,-0.914 0.891,-1.2695 0.375,-0.3555 0.824,-0.6484 1.351,-0.8672 0.531,-0.2148 1.102,-0.3516 1.727,-0.4023 V 26.043 h 1.207 v 0.9336 z m -4.188,4.1523 c 0,0.4063 0.067,0.7813 0.2,1.1289 0.136,0.3516 0.332,0.6602 0.582,0.9297 0.253,0.2695 0.562,0.4922 0.929,0.6719 0.371,0.1758 0.793,0.289 1.262,0.3398 v -6.1211 c -0.469,0.0547 -0.891,0.1641 -1.262,0.336 -0.367,0.1718 -0.679,0.3945 -0.929,0.6679 -0.254,0.2735 -0.446,0.5821 -0.582,0.9336 -0.133,0.3477 -0.2,0.7227 -0.2,1.1172 z m 7.125,0 c 0,-0.3984 -0.062,-0.7656 -0.199,-1.1172 -0.137,-0.3476 -0.328,-0.6562 -0.578,-0.9297 -0.25,-0.2695 -0.563,-0.4922 -0.926,-0.6679 -0.363,-0.1797 -0.777,-0.2891 -1.238,-0.3399 v 6.1055 c 0.461,-0.0508 0.875,-0.1641 1.238,-0.3399 0.363,-0.1796 0.668,-0.4023 0.926,-0.6718 0.25,-0.2696 0.441,-0.5782 0.578,-0.9297 0.137,-0.3477 0.199,-0.7227 0.199,-1.1172 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
      </svg> */}
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="451" height="437">
        <motion.path
          d="m132.27 126.94-95.05-.03c-1.22 0-2.4-.49-3.27-1.35-.86-.87-1.36-2.04-1.35-3.26l.08-64.3a4.6 4.6 0 0 1 2.26-3.96l36.49-21.61c1.45-.86 3.25-.86 4.69 0l36.89 21.78c1.41.83 2.27 2.34 2.27 3.97v42.55c0 1.22-.49 2.4-1.35 3.26-.86.86-2.04 1.35-3.26 1.35l-36.85-.05c-2.54 0-4.6-2.06-4.61-4.6l-.04-12.8a4.62 4.62 0 0 1 4.6-4.63h.01c2.54 0 4.6 2.06 4.61 4.6l.03 8.21 27.64.04V60.79L73.78 41.74 41.9 60.63l-.07 57.06 85.84.02.07-68.08-53.99-32.15-53.89 32.06.31 52.4c.02 2.55-2.04 4.62-4.58 4.64h-.03c-2.53 0-4.6-2.05-4.61-4.58l-.33-55.04c0-1.64.85-3.15 2.25-3.99L71.39 8.15c1.45-.87 3.26-.86 4.72 0l58.6 34.9c1.4.83 2.25 2.34 2.25 3.97v.16l-.08 75.15c0 2.54-2.07 4.61-4.61 4.61Z"
          fill="transparent"
          strokeWidth="2"
          stroke="#96c73a"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m149.19 77.46 2.23-1.75c.76 1.06 1.74 1.83 2.94 2.31s2.42.72 3.67.72 2.26-.2 3.23-.6c.96-.4 1.8-.96 2.51-1.69.71-.72 1.28-1.57 1.7-2.56.42-.99.69-2.05.79-3.2h-12.79v-2.58h12.73c-.15-1.1-.45-2.12-.89-3.05-.45-.93-1.03-1.73-1.75-2.39s-1.56-1.17-2.53-1.54-2.03-.56-3.2-.56c-.85 0-1.62.1-2.31.3-.69.2-1.3.45-1.83.75s-.98.64-1.35 1.02c-.37.38-.65.74-.84 1.08l-2-1.97c.38-.55.86-1.07 1.45-1.54.58-.48 1.24-.89 1.97-1.24s1.53-.63 2.4-.83 1.79-.3 2.77-.3c1.8 0 3.41.3 4.84.89 1.42.59 2.62 1.42 3.61 2.47.99 1.05 1.74 2.3 2.26 3.74s.78 3.02.78 4.74-.28 3.3-.83 4.74-1.34 2.69-2.35 3.74c-1.02 1.05-2.24 1.87-3.66 2.47s-3 .89-4.74.89c-1.02 0-1.97-.11-2.86-.32-.89-.21-1.71-.5-2.47-.86-.75-.36-1.43-.78-2.02-1.27a7.472 7.472 0 0 1-1.46-1.59ZM174.07 80.93V58.41h3.05v9.23c.51 0 1 .02 1.48.05.48.03.95.07 1.42.11l8.14-9.39h3.37l-8.37 10.12c2.48.85 4.42 2.29 5.82 4.33 1.4 2.04 2.38 4.73 2.93 8.08h-3.4c-.32-1.87-.77-3.48-1.35-4.84-.58-1.36-1.33-2.47-2.24-3.32s-2.01-1.48-3.29-1.88c-1.28-.39-2.78-.57-4.5-.52v10.56h-3.05ZM218.8 69.64c0 1.74-.3 3.34-.89 4.79-.59 1.45-1.42 2.7-2.48 3.75s-2.32 1.87-3.77 2.45c-1.45.58-3.03.88-4.72.88s-3.27-.29-4.72-.88c-1.45-.58-2.71-1.4-3.77-2.45s-1.89-2.3-2.48-3.75c-.59-1.45-.89-3.05-.89-4.79s.3-3.33.89-4.77c.59-1.44 1.42-2.68 2.48-3.72s2.32-1.85 3.77-2.43c1.45-.58 3.03-.87 4.72-.87s3.27.29 4.72.87c1.45.58 2.71 1.4 3.77 2.43 1.06 1.04 1.89 2.28 2.48 3.72.59 1.44.89 3.03.89 4.77Zm-3.28 0c0-1.23-.2-2.39-.6-3.48a8.9 8.9 0 0 0-1.72-2.88c-.74-.83-1.64-1.48-2.7-1.97-1.06-.49-2.25-.73-3.56-.73s-2.5.24-3.56.73a8.264 8.264 0 0 0-2.7 1.97 8.9 8.9 0 0 0-1.72 2.88c-.4 1.09-.6 2.25-.6 3.48s.2 2.43.6 3.53c.4 1.1.98 2.07 1.72 2.9.74.83 1.64 1.48 2.7 1.96 1.06.48 2.25.72 3.56.72s2.5-.24 3.56-.72 1.96-1.13 2.7-1.96a8.96 8.96 0 0 0 1.72-2.9c.4-1.1.6-2.28.6-3.53Z"
          fill="transparent"
          strokeWidth="2"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="M223.29 58.41h7.29c.85 0 1.71.09 2.59.27.88.18 1.67.49 2.37.92.7.44 1.27 1.02 1.72 1.77.45.74.67 1.68.67 2.8 0 1.21-.36 2.25-1.07 3.12-.71.87-1.66 1.45-2.85 1.75v.06c.72.09 1.38.27 1.99.56.6.29 1.13.66 1.58 1.13a5.1 5.1 0 0 1 1.05 1.67c.25.65.38 1.36.38 2.15 0 1.02-.21 1.92-.62 2.7-.41.78-1 1.45-1.75 1.99s-1.65.95-2.69 1.22c-1.04.28-2.18.41-3.4.41h-7.25V58.41Zm3.05 9.64h4.18c.53 0 1.06-.06 1.58-.19.53-.13.99-.33 1.39-.6s.73-.64.98-1.1c.25-.46.38-1.01.38-1.67s-.12-1.17-.36-1.61c-.24-.43-.59-.8-1.03-1.08s-.96-.49-1.55-.62c-.59-.13-1.24-.19-1.93-.19h-3.64v7.06Zm0 10.31h3.96c.72 0 1.42-.07 2.09-.21.68-.14 1.27-.37 1.79-.68.52-.32.93-.73 1.24-1.23s.46-1.12.46-1.86c0-1.34-.47-2.32-1.41-2.96-.94-.64-2.23-.95-3.88-.95h-4.25v7.89ZM244.41 80.93h-3.37l9.67-22.52h2.99l9.61 22.52h-3.47l-2.29-5.54h-10.88l-2.26 5.54Zm3.28-8.21h8.84l-4.39-10.88-4.45 10.88ZM274.03 80.93h-3.05V61.11h-7.25v-2.7h17.56v2.7h-7.25v19.82ZM285.13 80.93h-3.37l9.67-22.52h2.99l9.61 22.52h-3.47l-2.29-5.54h-10.88l-2.26 5.54Zm3.28-8.21h8.84l-4.39-10.88-4.45 10.88Z"
          fill="transparent"
          strokeWidth="2"
          stroke="#96c73a"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="M310.39 79.02c0 .55-.2 1.04-.6 1.46-.4.42-.91.64-1.53.64s-1.1-.21-1.51-.62c-.41-.41-.62-.9-.62-1.45s.2-1.07.6-1.48c.4-.41.91-.62 1.53-.62s1.12.2 1.53.6c.4.4.6.89.6 1.46ZM315.23 58.41h6.84c1.17 0 2.24.12 3.21.37.98.24 1.81.62 2.51 1.13s1.24 1.16 1.62 1.94c.38.79.57 1.72.57 2.8s-.2 2.02-.61 2.82c-.4.79-.96 1.45-1.67 1.97s-1.56.91-2.55 1.16c-.99.25-2.06.38-3.21.38h-3.67v9.96h-3.05V58.42Zm3.05 10.05h3.48c1.6 0 2.85-.31 3.75-.93.9-.62 1.36-1.57 1.36-2.84s-.46-2.27-1.37-2.84c-.92-.58-2.15-.86-3.7-.86h-3.51v7.48ZM346.69 59.2c1.54.13 2.97.47 4.28 1.02s2.44 1.28 3.39 2.2c.95.91 1.69 1.98 2.22 3.2.53 1.22.79 2.57.79 4.06s-.26 2.84-.79 4.07c-.53 1.23-1.27 2.3-2.22 3.21s-2.08 1.64-3.39 2.19-2.74.89-4.28 1.02v2.55h-3.05v-2.55c-1.57-.13-3.02-.47-4.34-1.02s-2.47-1.28-3.42-2.19c-.95-.91-1.7-1.98-2.24-3.21s-.81-2.59-.81-4.07.27-2.84.81-4.07 1.29-2.3 2.24-3.2c.95-.9 2.09-1.63 3.42-2.18 1.33-.55 2.77-.89 4.34-1.02v-2.35h3.05v2.35Zm-10.56 10.47c0 1.02.17 1.97.51 2.85.34.88.83 1.66 1.46 2.34.64.68 1.42 1.24 2.35 1.69.93.45 1.99.73 3.18.86V61.98c-1.19.13-2.25.41-3.18.84-.93.44-1.72 1-2.35 1.69-.64.69-1.12 1.47-1.46 2.35-.34.88-.51 1.82-.51 2.82Zm17.97 0c0-1-.17-1.93-.51-2.82-.34-.88-.82-1.66-1.46-2.34-.63-.68-1.41-1.24-2.33-1.69-.92-.45-1.96-.73-3.12-.86v15.4c1.16-.13 2.2-.41 3.12-.86.92-.45 1.69-1.01 2.33-1.69s1.12-1.46 1.46-2.34c.34-.88.51-1.82.51-2.82Z"
          fill="transparent"
          strokeWidth="2"
          stroke="#96c73a"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.circle cx="10.07" cy="113.83" r="0.1"
        stroke="#96c73a"
        fill="transparent"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={transition}
        strokeWidth="0.1"
      />
      <motion.circle cx="20.1" cy="113.83" r="0.1"
        stroke="#96c73a"
        initial={{ pathLength: 0 }}
        animate={{ pathLength: 1 }}
        transition={transition}
      />
      </svg> */}
        {/* <div className="contact_inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-5">
                                <div className="keepintouch_3">
                                    {
                                        ContactData.map(data => <CommunicationCard
                                            key={data.id}
                                            data={data}
                                        />)
                                    }
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7 offset-lg-1">
                                <div className="contact_us_1">
                                    <div className="section_header">
                                        <h6 className="section_sub_title">Contact Us</h6>
                                        <h1 className="section_title">Want to Ask anything?<br />
                                            Send Us a Mail Anytime</h1>
                                    </div>
                                    <form className="contact_form" action="contact.php" method="post">
                                        <div className="form-container">
                                            <div className="row">
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="name" className="form-control" placeholder="Your Name*" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="email" name="email" className="form-control" placeholder="Email Address*" required="" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="phone" className="form-control" placeholder="Phone No" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                    <div className="form-group">
                                                        <input type="text" name="subject" className="form-control" placeholder="Subject" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <textarea name="message" className="form-control" placeholder="Message Here*" required=""></textarea>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-lg-12">
                                                    <div className="form-group">
                                                        <input className="button" type="submit" value="Send Mail" name="submit" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* <Banner
                    title='Is Your House Secured Enough? Call Us to install Security Devices'
                    heading='Call Us Anytime'
                    phone='(+123)987.654.32'
                    bannerType='banner banner_bg_color'
                /> */}
      </div>
    </>
  );
};

export default ContactPage;
