import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';

export default function Map2() {
  const defaultState = {
    center: [59.755158, 30.532526],
    zoom: 12,
  };

  return (
    <YMaps>
      <Map defaultState={defaultState}
        width={'100%'}
        height={'600px'}>
        <Placemark geometry={[59.755158, 30.532526]} />
      </Map>
    </YMaps>
  );
}