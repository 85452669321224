import React, { useState } from "react";
import MobileNav from "./MobileNav/MobileNav";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Header = () => {
  // Hide header on scroll down
  window.onscroll = function () {
    myFunction();
  };
  function myFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.querySelector("header").classList.add("dark_mode");
      document.querySelector("header .top_bar").classList.add("top-up");
    } else {
      document.querySelector("header").classList.remove("dark_mode");
      document.querySelector("header .top_bar").classList.remove("top-up");
    }
  }

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  const transition = { duration: 2, yoyo: Infinity, ease: "linear" };
  const transition2 = { duration: 500, yoyo: Infinity, ease: "linear" };

  return (
    <>
      <header className="header">
        {/* <PreHeader /> */}

        <div className="top_bar" id="top-bar">
          <div className="container">
            <div className="top_bar_inner">
              <div className="header_social">
                {/* <h6>Соцсети</h6>
                                <ul className="top_social">
                                    <li className="facebook"><a href="#"><i className="ion-social-facebook"></i></a></li>
                                    <li className="twitter"><a href="#"><i className="ion-social-twitter"></i></a></li>
                                    <li className="dribbble"><a href="#"><i className="ion-social-dribbble-outline"></i></a></li>
                                    <li className="instagram"><a href="#"><i className="ion-social-instagram-outline"></i></a></li>
                                    <li className="linkedin"><a href="#"><i className="ion-social-linkedin"></i></a></li>
                                </ul> */}
              </div>
              <div className="header_info ">
                <div className="schedule">
                  <div
                    className="hide_icon d-md-none"
                    data-text="Каждый день (9.00 - 21.00)"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-clock-o"></i>
                    </span>
                  </div>
                  <div className="d-none d-md-block">
                    <span>
                      <i className="fa fa-clock-o"></i>
                    </span>{" "}
                    Каждый день <strong>(9.00 - 21.00)</strong>
                  </div>
                </div>
                <div className="schedule">
                  <div
                    className="hide_icon d-md-none"
                    data-text="builderrine@gmail.com"
                  >
                    {" "}
                    <span>
                      <i className="fa fa-envelope"></i>
                    </span>
                  </div>
                  <div className="d-none d-md-block">
                    <span>
                      <i className="fa fa-envelope"></i>
                    </span>{" "}
                    contact@ecovata.ru
                  </div>
                </div>
                {/* <div className="free_contact p-4">
                  <a href="/contact" className="btn">Связаться с нами</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="middle_bar">
          <div className="container">
            <div className="middle_bar_inner">
              <motion.div 
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="logo">
                <a href="/" className="light_mode_logo">
                  {/* <img src="images/ico/eco.svg" alt="logo" /> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="140" height="55">
        <motion.path
          d="m 1.57813,52.3633 c 0.05859,0 0.10156,0.0469 0.10156,0.1015 0,0.0586 -0.04297,0.1055 -0.10156,0.1055 -0.05079,0 -0.09766,-0.0469 -0.09766,-0.1055 0,-0.0546 0.04687,-0.1015 0.09766,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 5.55469,52.3633 c 0.05469,0 0.10156,0.0469 0.10156,0.1015 0,0.0586 -0.04687,0.1055 -0.10156,0.1055 -0.05469,0 -0.10156,-0.0469 -0.10156,-0.1055 0,-0.0546 0.04687,-0.1015 0.10156,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 1.57813,48.6289 c 0.05859,0 0.10156,0.0469 0.10156,0.1055 0,0.0586 -0.04297,0.1054 -0.10156,0.1054 -0.05079,0 -0.09766,-0.0468 -0.09766,-0.1054 0,-0.0586 0.04687,-0.1055 0.09766,-0.1055 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 5.55469,48.6289 c 0.05469,0 0.10156,0.0469 0.10156,0.1055 0,0.0586 -0.04687,0.1054 -0.10156,0.1054 -0.05469,0 -0.10156,-0.0468 -0.10156,-0.1054 0,-0.0586 0.04687,-0.1055 0.10156,-0.1055 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 3.47266,43.8594 c 0,-6.9571 0,-13.9219 0,-20.8828 0,-0.1875 0,-0.375 0,-0.5586 0.0039,-0.0742 0,-0.2539 0.23047,-0.4102 L 4.10156,21.7773 26.1445,9.0625 c 0.1719,-0.10156 0.3477,-0.19922 0.5196,-0.29687 0.1328,-0.05079 0.2812,-0.10938 0.457,-0.01954 L 27.6602,9.05859 50.1016,22 c 0.125,0.0977 0.2343,0.1797 0.2343,0.4023 l -0.0039,29.504 c -0.0234,0.4257 -0.3281,0.4414 -0.4531,0.4414 H 12.7344 c -0.3047,0 -0.4102,-0.2891 -0.4063,-0.4414 L 12.3242,26.7422 c 0,-0.1211 0.043,-0.3125 0.2539,-0.4102 l 14.0078,-8.0703 c 0.2266,-0.1133 0.3282,-0.1836 0.5938,-0.0273 l 14.3281,8.1211 c 0.1914,0.082 0.2422,0.2968 0.2422,0.3867 v 16.6719 c 0,0.3828 -0.3125,0.4336 -0.4531,0.4492 H 27.3203 c -0.3398,0 -0.4023,-0.3242 -0.418,-0.4258 v -4.8828"
          fill="transparent"
          strokeWidth="3.9"
          stroke="#96c73a"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 26.832,33.793 c 0.0508,0 0.0977,0.0508 0.0977,0.1054 0,0.0586 -0.0469,0.1055 -0.0977,0.1055 -0.0586,0 -0.1054,-0.0469 -0.1054,-0.1055 0,-0.0546 0.0468,-0.1054 0.1054,-0.1054 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 24.4805,31.0664 c 0.0547,0 0.0976,0.0469 0.0976,0.1055 0,0.0586 -0.0429,0.1054 -0.0976,0.1054 -0.0586,0 -0.0977,-0.0468 -0.0977,-0.1054 0,-0.0586 0.0391,-0.1055 0.0977,-0.1055 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 29.1016,31.0664 c 0.0547,0 0.0976,0.0469 0.0976,0.1055 0,0.0586 -0.0429,0.1054 -0.0976,0.1054 C 29.043,31.2773 29,31.2305 29,31.1719 c 0,-0.0586 0.043,-0.1055 0.1016,-0.1055 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 22.332,28.4102 c 0.0547,0 0.1016,0.0429 0.1016,0.1015 0,0.0586 -0.0469,0.1055 -0.1016,0.1055 -0.0547,0 -0.0976,-0.0469 -0.0976,-0.1055 0,-0.0586 0.0429,-0.1015 0.0976,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 26.832,28.4102 c 0.0508,0 0.0977,0.0429 0.0977,0.1015 0,0.0586 -0.0469,0.1055 -0.0977,0.1055 -0.0586,0 -0.1054,-0.0469 -0.1054,-0.1055 0,-0.0586 0.0468,-0.1015 0.1054,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={transition}
        />
        <motion.path
          d="m 31.3242,28.4102 c 0.0586,0 0.1055,0.0429 0.1055,0.1015 0,0.0586 -0.0469,0.1055 -0.1055,0.1055 -0.0508,0 -0.0976,-0.0469 -0.0976,-0.1055 0,-0.0586 0.0468,-0.1015 0.0976,-0.1015 z"
          fill="transparent"
          strokeWidth="3"
          stroke="#adadad"
          strokeLinecap="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1, delay: 5 }}
          transition={transition}
        />
        <motion.path
          d="m 57.7148,34.2148 0.8829,-0.6914 c 0.3007,0.418 0.6914,0.7266 1.1679,0.9141 0.4727,0.1914 0.961,0.2852 1.4571,0.2852 0.4961,0 0.8945,-0.0782 1.2812,-0.2383 0.3789,-0.1563 0.7109,-0.3789 0.9922,-0.6719 0.2812,-0.2852 0.5078,-0.6211 0.6758,-1.0117 0.1679,-0.3945 0.2734,-0.8164 0.3125,-1.2695 h -5.0703 v -1.0235 h 5.0468 c -0.0586,-0.4375 -0.1796,-0.8437 -0.3515,-1.2109 -0.1797,-0.3711 -0.4102,-0.6875 -0.6953,-0.9492 -0.2852,-0.2579 -0.6211,-0.4649 -1.0039,-0.6094 -0.3829,-0.1445 -0.8047,-0.2227 -1.2696,-0.2227 -0.3359,0 -0.6406,0.0391 -0.914,0.1172 -0.2774,0.082 -0.5157,0.1797 -0.7266,0.3008 -0.2109,0.1172 -0.3867,0.2539 -0.5352,0.4023 -0.1484,0.1524 -0.2578,0.293 -0.3359,0.4297 l -0.793,-0.7812 c 0.1524,-0.2188 0.3438,-0.4258 0.5743,-0.6094 0.2304,-0.1914 0.4961,-0.3555 0.7851,-0.4961 0.2891,-0.1367 0.6055,-0.2461 0.9531,-0.3281 0.3399,-0.0781 0.7071,-0.1172 1.0938,-0.1172 0.7148,0 1.3516,0.1172 1.9219,0.3555 0.5625,0.2304 1.039,0.5586 1.4336,0.9765 0.3906,0.418 0.6875,0.9141 0.8945,1.4844 0.207,0.5703 0.3086,1.1953 0.3086,1.8789 0,0.6797 -0.1094,1.3086 -0.3281,1.8789 -0.2188,0.5742 -0.5313,1.0664 -0.9336,1.4844 -0.4024,0.418 -0.8868,0.7422 -1.4493,0.9805 -0.5664,0.2382 -1.1914,0.3554 -1.8789,0.3554 -0.4062,0 -0.7851,-0.0468 -1.1367,-0.1289 -0.3515,-0.0859 -0.6797,-0.1992 -0.9804,-0.3437 -0.2969,-0.1407 -0.5665,-0.3086 -0.8008,-0.5 -0.2344,-0.1953 -0.4297,-0.4063 -0.5782,-0.6328 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 67.582,35.5898 v -8.9296 h 1.211 v 3.6601 c 0.1992,0 0.3945,0.0078 0.5859,0.0195 0.1914,0.0118 0.3789,0.0274 0.5625,0.043 l 3.2305,-3.7226 h 1.3359 l -3.3203,4.0117 c 0.9844,0.3398 1.7539,0.9101 2.3086,1.7187 0.5586,0.8086 0.9453,1.8789 1.1641,3.2032 h -1.3477 c -0.1289,-0.7383 -0.3086,-1.379 -0.5391,-1.918 -0.2265,-0.5391 -0.5273,-0.9766 -0.8867,-1.3164 -0.3633,-0.336 -0.7969,-0.5899 -1.3047,-0.7461 -0.5078,-0.1563 -1.1054,-0.2266 -1.7851,-0.207 v 4.1875 h -1.211 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 85.3242,31.1133 c 0,0.6914 -0.1211,1.3242 -0.3515,1.8984 -0.2344,0.5742 -0.5625,1.0703 -0.9844,1.4883 -0.4219,0.418 -0.9219,0.7422 -1.4961,0.9727 -0.5742,0.2304 -1.2031,0.3476 -1.8711,0.3476 -0.6719,0 -1.2969,-0.1133 -1.875,-0.3476 -0.5742,-0.2305 -1.0742,-0.5547 -1.4922,-0.9727 -0.4219,-0.418 -0.75,-0.9141 -0.9844,-1.4883 -0.2343,-0.5742 -0.3554,-1.207 -0.3554,-1.8984 0,-0.6875 0.1211,-1.3203 0.3554,-1.8906 0.2344,-0.5743 0.5625,-1.0625 0.9844,-1.4766 0.418,-0.4102 0.918,-0.7344 1.4922,-0.9648 0.5781,-0.2305 1.2031,-0.3438 1.875,-0.3438 0.668,0 1.2969,0.1133 1.8711,0.3438 0.5742,0.2304 1.0742,0.5546 1.4961,0.9648 0.4219,0.4141 0.75,0.9023 0.9844,1.4766 0.2304,0.5703 0.3515,1.2031 0.3515,1.8906 z m -1.3008,0 c 0,-0.4883 -0.082,-0.9453 -0.2382,-1.3789 -0.1602,-0.4336 -0.3907,-0.8164 -0.6797,-1.1446 -0.2969,-0.3281 -0.6524,-0.5859 -1.0742,-0.7812 -0.418,-0.1953 -0.8907,-0.2891 -1.4102,-0.2891 -0.5195,0 -0.9922,0.0938 -1.4102,0.2891 -0.4218,0.1953 -0.7812,0.457 -1.0742,0.7812 -0.2929,0.3282 -0.5234,0.711 -0.6836,1.1446 -0.1562,0.4336 -0.2343,0.8906 -0.2343,1.3789 0,0.4883 0.0781,0.9648 0.2343,1.3984 0.1602,0.4375 0.3907,0.8242 0.6836,1.1524 0.293,0.3281 0.6524,0.5859 1.0742,0.7773 0.418,0.1914 0.8907,0.2852 1.4102,0.2852 0.5195,0 0.9922,-0.0938 1.4102,-0.2852 0.4218,-0.1914 0.7773,-0.4492 1.0742,-0.7773 0.289,-0.3282 0.5195,-0.711 0.6797,-1.1524 0.1562,-0.4336 0.2382,-0.9023 0.2382,-1.3984 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d=""
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="M 87.1055,26.6602 H 90 c 0.3359,0 0.6758,0.0351 1.0234,0.1054 0.3516,0.0742 0.6641,0.1953 0.9414,0.3633 0.2774,0.1758 0.504,0.4063 0.6797,0.7031 0.1797,0.2969 0.2696,0.668 0.2696,1.1133 0,0.4766 -0.1446,0.8906 -0.4258,1.2344 -0.2813,0.3476 -0.6602,0.5742 -1.1289,0.6953 v 0.0273 c 0.2812,0.0313 0.5469,0.1016 0.789,0.2188 0.2383,0.1172 0.4454,0.2617 0.625,0.4492 0.1797,0.1875 0.3164,0.4063 0.418,0.6641 0.0977,0.2578 0.1484,0.5351 0.1484,0.8515 0,0.4024 -0.082,0.7579 -0.246,1.0704 -0.1602,0.3085 -0.3946,0.5742 -0.6915,0.789 -0.2968,0.211 -0.6562,0.3789 -1.0703,0.4844 -0.4101,0.1094 -0.8632,0.1601 -1.3476,0.1601 h -2.875 v -8.9296 z m 1.207,3.8242 h 1.6602 c 0.2109,0 0.4218,-0.0274 0.625,-0.0781 0.2109,-0.0508 0.3945,-0.129 0.5546,-0.2383 0.1602,-0.1055 0.2891,-0.25 0.3868,-0.4336 0.1015,-0.1836 0.1523,-0.4024 0.1523,-0.6641 0,-0.2617 -0.0508,-0.4648 -0.1406,-0.6406 -0.0977,-0.168 -0.2383,-0.3164 -0.4102,-0.4258 -0.1758,-0.1094 -0.3828,-0.1953 -0.6133,-0.2461 -0.2382,-0.0508 -0.496,-0.0742 -0.7656,-0.0742 h -1.4492 z m 0,4.0859 h 1.5742 c 0.2852,0 0.5625,-0.0234 0.8281,-0.082 0.2696,-0.0547 0.504,-0.1485 0.711,-0.2695 0.207,-0.125 0.3672,-0.2891 0.4922,-0.4883 0.1211,-0.1953 0.1797,-0.4414 0.1797,-0.7383 0,-0.5313 -0.1875,-0.918 -0.5586,-1.1719 C 91.168,31.5664 90.6563,31.4414 90,31.4414 h -1.6875 z"
          fill="#96c73a"
          strokeWidth="0"
          stroke="#96c73a"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 95.4844,35.5898 h -1.3399 l 3.836,-8.9296 h 1.1875 l 3.809,8.9296 h -1.375 l -0.911,-2.1953 h -4.3121 z m 1.3008,-3.2539 h 3.5038 l -1.7421,-4.3164 z"
          fill="#96c73a"
          strokeWidth="0"
          stroke="#96c73a"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 107.23,35.5898 h -1.207 v -7.8593 h -2.878 v -1.0703 h 6.964 v 1.0703 h -2.875 v 7.8593 z"
          fill="#96c73a"
          strokeWidth="0"
          stroke="#96c73a"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 111.637,35.5898 h -1.34 l 3.836,-8.9296 h 1.187 l 3.809,8.9296 h -1.375 l -0.91,-2.1953 h -4.313 z m 1.301,-3.2539 h 3.503 l -1.742,-4.3164 z"
          fill="#96c73a"
          strokeWidth="0"
          stroke="#96c73a"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 121.652,34.8359 c 0,0.2188 -0.078,0.4102 -0.238,0.5782 -0.156,0.1679 -0.359,0.2539 -0.605,0.2539 -0.246,0 -0.438,-0.086 -0.598,-0.2461 -0.164,-0.1641 -0.246,-0.3555 -0.246,-0.5742 0,-0.2188 0.078,-0.4258 0.238,-0.5899 0.156,-0.164 0.36,-0.2461 0.606,-0.2461 0.246,0 0.445,0.0821 0.605,0.2383 0.16,0.1602 0.238,0.3555 0.238,0.5781 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 123.57,26.6602 h 2.719 c 0.461,0 0.887,0.0468 1.27,0.1484 0.39,0.0937 0.718,0.2422 0.996,0.4453 0.277,0.2031 0.492,0.4609 0.644,0.7695 0.149,0.3125 0.223,0.6836 0.223,1.1094 0,0.4297 -0.078,0.8047 -0.238,1.1211 -0.161,0.3125 -0.383,0.5742 -0.664,0.7813 -0.282,0.207 -0.622,0.3593 -1.012,0.457 -0.395,0.1016 -0.817,0.1523 -1.274,0.1523 h -1.457 v 3.9493 h -1.207 v -8.9297 z m 1.211,3.9843 h 1.383 c 0.633,0 1.129,-0.1211 1.488,-0.3672 0.356,-0.246 0.539,-0.625 0.539,-1.125 0,-0.5039 -0.183,-0.9023 -0.546,-1.1289 -0.364,-0.2304 -0.852,-0.3398 -1.465,-0.3398 h -1.391 v 2.9648 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
        <motion.path
          d="m 136.051,26.9727 c 0.613,0.0507 1.179,0.1875 1.695,0.4023 0.524,0.2188 0.973,0.5078 1.348,0.875 0.379,0.3594 0.668,0.7852 0.879,1.2695 0.215,0.4805 0.316,1.0196 0.316,1.6094 0,0.5899 -0.101,1.125 -0.316,1.6133 -0.211,0.4883 -0.5,0.9141 -0.879,1.2734 -0.375,0.3633 -0.824,0.6524 -1.348,0.8711 -0.516,0.2188 -1.082,0.3516 -1.695,0.4063 v 1.0078 h -1.207 V 35.293 c -0.625,-0.0547 -1.199,-0.1875 -1.727,-0.4063 -0.519,-0.2187 -0.976,-0.5078 -1.351,-0.8711 -0.379,-0.3593 -0.676,-0.7851 -0.891,-1.2734 -0.215,-0.4883 -0.32,-1.0274 -0.32,-1.6133 0,-0.5859 0.105,-1.1289 0.32,-1.6133 0.215,-0.4883 0.512,-0.914 0.891,-1.2695 0.375,-0.3555 0.824,-0.6484 1.351,-0.8672 0.531,-0.2148 1.102,-0.3516 1.727,-0.4023 V 26.043 h 1.207 v 0.9336 z m -4.188,4.1523 c 0,0.4063 0.067,0.7813 0.2,1.1289 0.136,0.3516 0.332,0.6602 0.582,0.9297 0.253,0.2695 0.562,0.4922 0.929,0.6719 0.371,0.1758 0.793,0.289 1.262,0.3398 v -6.1211 c -0.469,0.0547 -0.891,0.1641 -1.262,0.336 -0.367,0.1718 -0.679,0.3945 -0.929,0.6679 -0.254,0.2735 -0.446,0.5821 -0.582,0.9336 -0.133,0.3477 -0.2,0.7227 -0.2,1.1172 z m 7.125,0 c 0,-0.3984 -0.062,-0.7656 -0.199,-1.1172 -0.137,-0.3476 -0.328,-0.6562 -0.578,-0.9297 -0.25,-0.2695 -0.563,-0.4922 -0.926,-0.6679 -0.363,-0.1797 -0.777,-0.2891 -1.238,-0.3399 v 6.1055 c 0.461,-0.0508 0.875,-0.1641 1.238,-0.3399 0.363,-0.1796 0.668,-0.4023 0.926,-0.6718 0.25,-0.2696 0.441,-0.5782 0.578,-0.9297 0.137,-0.3477 0.199,-0.7227 0.199,-1.1172 z"
          fill="#adadad"
          strokeWidth="0"
          stroke="#adadad"
          strokeLinecap=""
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
        />
      </svg>
                </a>
                <a href="/" className="dark_mode_logo">
                  <img src="images/ico/eco.svg" alt="logo" />
                </a>
              </motion.div>

              <div className="header_right_part flex space-x-0">
                <div className="mainnav">
                  <ul className="main_menu">
                    {/* <li className="menu-item menu-item-has-children active"><a href="/">Home</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item active"><a href="/">Home One</a></li>
                                                <li className="menu-item"><a href="/home-2">Home Two</a></li>
                                            </ul>
                                        </li> */}
                    <motion.li
                    initial={{ opacity: 0 }}
                    animate={{ y: [-100, 0], opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 0.8 }}
                    whileHover={{ scale: 1.05 }}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}}
                    className="menu-item">
                      <Link to="/service-details">Услуги</Link>
                      {/* <ul className="sub-menu">
                                                <li className="menu-item"><a href="/services">Service One</a></li>
                                                <li className="menu-item"><a href="/services-2">Service Two</a></li>
                                                <li className="menu-item"><a href="/service-details">Service Details</a></li>
                                            </ul> */}
                    </motion.li>
                    <motion.li
                    initial={{ opacity: 0 }}
                    animate={{ y: [-100, 0], opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 0.8, delay: 0.2 }}
                    whileHover={{ scale: 1.05 }}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}} 
                    className="menu-item">
                      <Link to="/photo">Фотогалерея</Link>
                      {/* <ul className="sub-menu">
                                                <li className="menu-item"><a href="/project">Projects One</a></li>
                                                <li className="menu-item"><a href="/project-2">Peojects Two</a></li>
                                                <li className="menu-item"><a href="/project-details">Peojects Details</a></li>
                                            </ul> */}
                    </motion.li>
                    {/* <li className="menu-item menu-item-has-children"><a href="#">Страницы</a>
                                            <ul className="sub-menu">
                                                <li className="menu-item"><a href="/about">О нас</a></li>
                                                {/* <li className="menu-item"><a href="/team">Team</a></li> */}
                    {/* <li className="menu-item"><a href="/faq">FAQ</a></li> */}
                    {/* <li className="menu-item menu-item-has-children"><a href="#">Товары</a>
                                                    <ul className="sub-menu">
                                                        <li className="menu-item"><a href="/shop-1">Shop One</a></li>
                                                        <li className="menu-item"><a href="/shop-2">Shop Two</a></li>
                                                        <li className="menu-item"><a href="/shop-3">Shop Three</a></li>
                                                        <li className="menu-item"><a href="/shop-4">Shop Four</a></li>
                                                        <li className="menu-item"><a href="/product">Product Details</a></li>
                                                        <li className="menu-item"><a href="/cart">Cart</a></li>
                                                        <li className="menu-item"><a href="/checkout">Checkout</a></li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li> */}
                    {/* <li className="menu-item"><Link to="/blog-2">Об эковате</Link>
                                                <ul className="sub-menu">
                                                <li className="menu-item"><a href="/blog-1">Blog One</a></li>
                                                <li className="menu-item"><a href="/blog-2">Blog Two</a></li>
                                                <li className="menu-item"><a href="/blog-details">Blog Details</a></li>
                                            </ul>
                                        </li> */}
                    <motion.li
                    initial={{ opacity: 0 }}
                    animate={{ y: [-100, 0], opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 0.8, delay: 0.4 }}
                    whileHover={{ scale: 1.05 }}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}} 
                    className="menu-item">
                      <Link to="/contact">Контакты</Link>
                    </motion.li>
                    <motion.li
                    initial={{ opacity: 0 }}
                    animate={{ y: [-100, 0], opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 0.8, delay: 0.6 }}
                    whileHover={{ scale: 1.05 }}
                    onHoverStart={e => {}}
                    onHoverEnd={e => {}}
                    className="menu-item">
                      <Link to="/price">Цены</Link>
                    </motion.li>
                  </ul>
                </div>
                <div className="phone">
                  <a href="tel:+7(981)180-62-76">
                    <i className="fa fa-phone btn_phone" aria-hidden="true"></i>
                  </a>
                  <div className="mx-1">
                    <span className="text-[10px]" onclick="tel:+7(981)180-62-76">
                      +7(981)180-62-76
                    </span>
                  </div>
                  <a href="tel:+7(995)911-10-14">
                    <i className="fa fa-phone btn_phone" aria-hidden="true"></i>
                  </a>
                  <div className="mx-1">
                    <span className="text-[10px]" onclick="tel:+7(995)911-10-14">
                      +7(995)911-10-14
                    </span>
                  </div>
                </div>
                {/* <div className='header_search' onClick={handleToggle}>
                                    <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                </div>
                                <div id='open_search' className={isActive ? null : "active"}>
                                    <form className="search_form" action="search.php">
                                        <input type="text" name="search" className="keyword form-control" placeholder="Search..." />
                                        <button type="submit" className="form-control-submit"><i className="ion-ios-search"></i></button>
                                    </form>
                                </div> */}

                <button
                  className="ma5menu__toggle position-relative d-lg-none"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasHome"
                  aria-controls="offcanvasHome"
                >
                  <i className="ion-android-menu"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <MobileNav />
    </>
  );
};

export default Header;
