import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import ProjectCard02 from '../../Components/ProjectElements/ProjectCard02';
import Banner from '../Banner';

const ProjectsContainer02 = () => {
    const ProjectData02 = [
        {
            id: '1',
            img: 'images/projects_images/1.jpg',
            tag: 'walls',
            title: 'Large Garden Lodge',
            city: 'NEW YORK',
        },
        {
            id: '2',
            img: 'images/projects_images/2.jpg',
            tag: 'horizontal top',
            title: 'Commercial Building',
            city: 'NEW YORK',
        },
        {
            id: '3',
            img: 'images/projects_images/3.jpg',
            tag: 'walls',
            title: 'Modern Pent House',
            city: 'NEW YORK',
        },
        {
            id: '4',
            img: 'images/projects_images/4.jpg',
            tag: 'walls',
            title: 'Large Swiming Pool',
            city: 'NEW YORK',
        },
        {
            id: '5',
            img: 'images/projects_images/5.jpg',
            tag: 'walls',
            title: 'Highrise Architecture',
            city: 'NEW YORK',
        },
        {
            id: '6',
            img: 'images/projects_images/6.jpg',
            tag: 'residential',
            title: 'Curve Sky Tower',
            city: 'NEW YORK',
        },
        {
            id: '7',
            img: 'images/projects_images/7.jpg',
            tag: 'residential',
            title: 'Shopping Mall',
            city: 'NEW YORK',
        },
        {
            id: '8',
            img: 'images/projects_images/8.jpg',
            tag: 'walls',
            title: 'Wonderful Bridge',
            city: 'NEW YORK',
        },
        {
            id: '9',
            img: 'images/projects_images/9.jpg',
            tag: 'walls',
            title: 'Small House Area',
            city: 'NEW YORK',
        },
        {
            id: '10',
            img: 'images/projects_images/10.jpg',
            tag: 'walls horizontal',
            title: 'Modern Architecture',
            city: 'NEW YORK',
        },
        {
            id: '11',
            img: 'images/projects_images/11.jpg',
            tag: 'walls horizontal',
            title: 'Large Garden Lodge',
            city: 'NEW YORK',
        },
        {
            id: '12',
            img: 'images/projects_images/12.jpg',
            tag: 'horizontal',
            title: 'Commercial Building',
            city: 'NEW YORK',
        },
        {
            id: '13',
            img: 'images/projects_images/13.jpg',
            tag: 'horizontal',
            title: 'Modern Pent House',
            city: 'NEW YORK',
        },
        {
            id: '14',
            img: 'images/projects_images/14.jpg',
            tag: 'horizontal top',
            title: 'Large Swiming Pool',
            city: 'NEW YORK',
        },
        {
            id: '15',
            img: 'images/projects_images/15.jpg',
            tag: 'walls',
            title: 'Highrise Architecture',
            city: 'NEW YORK',
        },
        {
            id: '16',
            img: 'images/projects_images/16.jpg',
            tag: 'walls',
            title: 'Curve Sky Tower',
            city: 'NEW YORK',
        },
        {
            id: '17',
            img: 'images/projects_images/22.jpg',
            tag: 'horizontal top',
            title: 'Shopping Mall',
            city: 'NEW YORK',
        },
        {
            id: '18',
            img: 'images/projects_images/21.jpg',
            tag: 'horizontal top',
            title: 'Wonderful Bridge',
            city: 'NEW YORK',
        },
        {
            id: '19',
            img: 'images/projects_images/19.jpg',
            tag: 'walls',
            title: 'Small House Area',
            city: 'NEW YORK',
        },
        {
            id: '20',
            img: 'images/projects_images/20.jpg',
            tag: 'horizontal',
            title: 'Modern Architecture',
            city: 'NEW YORK',
        },

    ];

    const [filterKey, setFilterKey] = useState('*')

    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.element-item',
            percentPosition: true,
            // layoutMode: 'packery',
            // masonry: {
            //     columnWidth: '.grid-sizer'
            // },
            transformsEnabled: true,
            transitionDuration: "200ms",
            resize: true,
            fitWidth: true,
            // columnWidth: '.grid-sizer',
        })

        // cleanup
        return () => Isotope.current.destroy()
    }, []);

    // handling filter key change
    useEffect(() => {
        filterKey === '*'
            ? Isotope.current.arrange({ filter: `*` })
            : Isotope.current.arrange({ filter: `.${filterKey}` })
    }, [filterKey]);

    const handleFilterKeyChange = key => () => setFilterKey(key);
    return (
        <div class="main_wrapper">
            <div class="project_iso project_iso1 pd_tp_50 pb-0 ">
                <div class="container-fluid g-0">
                    <div class="row g-0">
                        <div class="col">
                            <div class="button-group filters-button-group">
                                <button className="button" onClick={handleFilterKeyChange('*')}>Все<sup className="filter-count"></sup></button>
                                <button className="button" onClick={handleFilterKeyChange('walls')}>Стены <sup className="filter-count"></sup></button>
                                <button className="button" onClick={handleFilterKeyChange('top')}>Чердак <sup className="filter-count"></sup></button>
                                <button className="button" onClick={handleFilterKeyChange('horizontal')}>Перекрытия <sup className="filter-count"></sup></button>
                                {/* <button className="button" onClick={handleFilterKeyChange('etc')}>Прочее <sup className="filter-count"></sup></button> */}
                            </div>

                            <div className="grid grid-5">

                                {
                                    ProjectData02.map(data => <ProjectCard02
                                        key={data.id}
                                        data={data}
                                    />)
                                }

                            </div>

                        </div>
                    </div>

                </div>
            </div>
            {/* <Banner
                title='Is Your House Secured Enough? Call Us to install Security Devices'
                heading='Call Us Anytime'
                phone='(+123)987.654.32'
                bannerType='banner banner_bg_color'
            /> */}
        </div>
    );
};

export default ProjectsContainer02;